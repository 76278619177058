@mixin breakpoint3 {
  @media screen and (min-width: 736px) {
    @content;
  }
}

nav#menu {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  overflow-y: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-box-shadow: 32px 30px 79px -50px rgba(255,255,255,0.5);
  -moz-box-shadow: 32px 30px 79px -50px rgba(255,255,255,0.5);
  box-shadow: 32px 30px 79px -50px rgba(255,255,255,0.5);
  min-width: 150px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: transform 0.2s ease-in, box-shadow 0.4s;
  @include breakpoint3 {
    opacity: 0.6;
  }
  & ul {
    width: 100%;
    height: 40vh;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    list-style-type: none;
    padding-left: 35px;
    & li {
      & input {
        display: none;
        & + label {
          cursor: pointer;
          border-right: 3px solid transparent;
          box-sizing: border-box;
          padding-left: 0px;
          & div {
            height: 100%;
            display: flex;
            align-items: center;
          }
          &:hover div {
            font-weight: bolder;
            border-right: 3px solid white;
          }
        }
        &:checked + label div {
          border-right: 3px solid grey;
          font-weight: bolder;
        }
      }
    }
  }
  &.slideOut {
    transition: transform 0.2s, box-shadow 0.4s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transform: translateX(-150px);
    -ms-transform: translateX(-150px);
  }
}
