#hamburger-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 20px;
  & input.hamburger {
    display: none;
    &+ label {
      cursor: pointer;
      position: relative;
      z-index: 2;
      width: 34px;
      display: flex;
      & div {
        height: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        & .hamburger.layers {
          border-radius: 5px;
          opacity: 100%;
          position: relative;
          top: 0px;
          width: 34px;
          height: 3px;
          background-color: white;
          transition: transform 0.4s ease-in 0.2s, top 0.4s ease-in 0.4s, opacity 0.4s ease-out 0.4s, background-color 0.4s ease-in, left 0.4s;
        }
      }
      &:hover {
        & div.hamburger.layers {
          background-color: grey;
        }
      }
    }
    &:checked {
      &+ label {
        height: 24px;
        & div {
          height: 26px;
          & .hamburger.layers:nth-child(2) {
            opacity: 0;
          }
          & .hamburger.layers {
            background-color: white!important;
            position: relative;
            transition: transform 0.4s ease-out 0.2s, top 0.4s ease-out, opacity 0.4s ease-in 0.1s, left 0.4s;
          }
          & .hamburger.layers:nth-child(1) {
            top: 11.5px;
            transform: rotate(45deg);
          }
          & .hamburger.layers:nth-child(3) {
            top: -11px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
