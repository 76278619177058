@mixin breakpoint1 {
  @media screen and (min-width: 249px) {
    @content;
  }
}

@mixin breakpoint2 {
  @media screen and (min-width: 380px) {
    @content;
  }
}

@mixin breakpoint3 {
  @media screen and (min-width: 736px) {
    @content;
  }
}

@mixin breakpoint4 {
  @media screen and (min-width: 1050px) {
    @content;
  }
}

#home {
  height: 100vh!important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent!important;
  @include breakpoint3 {
  }
  & div#info {
    width: 100%;
    text-align: center;

    & > * {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include breakpoint2 {
      width: 70%;
    }
    @include breakpoint4 {
      width: 50%;
    }
    & .title {
      cursor: pointer;
      margin-top: 0px;
      font-size: 3em;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    & #socials {
      display: flex;
      justify-content: center;
      & div {
        cursor: pointer;
        margin: 10px;
        height: 21px;
        width: 21px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: background-image 0.2s ease-in;
        &.github {
          background-image: url("../../Media/github.png");
          &:hover {
            background-image: url("../../Media/github-hover.png");
          }
        }
        &.linkedin {
          background-image: url("../../Media/linkedin.png");
          &:hover {
            background-image: url("../../Media/linkedin-hover.png");
          }
        }
      }
    }
  }
}
