@mixin breakpoint1 {
  @media screen and (min-width: 249px) {
    @content;
  }
}
@mixin breakpoint2 {
  @media screen and (min-width: 380px) {
    @content;
  }
}
@mixin breakpoint3 {
  @media screen and (min-width: 736px) {
    @content;
  }
}

body {
  font-family: "roboto", sans-serif;
  @include breakpoint3 {
  }
  & .App {
    font-size: 0.9em;
    height: 100vh;
    @include breakpoint3 {
    }
    & header {
      position: fixed;
      z-index: 3;
      padding: 20px;
      display: flex;
      flex-direction: column;
      @include breakpoint3 {
      }
    }
    & #background {
      position: fixed;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url("Media/path.jpg");
      @include breakpoint3 {
      }
    }
    & #container {
      overflow-y: hidden;
      color: white;
      height: 100vh;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      & > article {
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        min-height: 100vh;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        & > .wrapper {
          max-width: 1136px;
        }
      }
    }
  }
}
