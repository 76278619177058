@mixin breakpoint2 {
  @media screen and (min-width: 380px) {
    @content;
  }
}
@mixin breakpoint3 {
  @media screen and (min-width: 736px) {
    @content;
  }
}

#education {
  & .wrapper {
    display: flex;
    justify-content: center;
    & section {
      display: flex;
      flex-wrap: wrap;
      margin-top:  40px;
      width: 100vw;
      @include breakpoint3 {
        display: block;
        margin-top: 0px;
      }
      & #title {
        margin-left: 20px;
        margin-right: 20px;
        align-items: center;
        @include breakpoint3 {
          display: flex;
        }
        & #brief {
          font-size: 1.1em;
          @include breakpoint3 {
            font-size: 1.3em;
          }
        }
      }
      & #technologies {
        text-align: justify;
        width: 100%;
        @include breakpoint3 {
          display: flex;
        }
        & > div {
          flex: 1;
          margin-left: 20px;
          margin-right: 20px;
          &:nth-child(2) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            & > * {
              flex: 1 1 auto;
            }
            @include breakpoint3 {
              display: block;
            }
          }
        }
      }
      & #badges {
        &.inline {
          display: none;
          @include breakpoint3 {
            display: flex;
          }
        }
        &.side {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include breakpoint3 {
            display: none;
          }
        }
        display: flex;
        background-size: contain;
        justify-content: center;
        & div {
          height: 100px;
          width: 100px;
          background-size: cover;
          @include breakpoint3 {
            height: 150px;
            width: 150px;
          }
        }
        & #ccp {
          background-image: url("../../Media/aws-certified-cloud-practitioner.png")
        }
        & #awsrs {
          background-image: url("../../Media/aws-re-start-graduate.png")
        }
      }
    }
  }
}
