@mixin breakpoint2 {
  @media screen and (min-width: 380px) {
    @content;
  }
}
@mixin breakpoint3 {
  @media screen and (min-width: 736px) {
    @content;
  }
}

#portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  & .wrapper {
    display: flex;
    & .sliderNav {
      width: 50px;
      display: flex;
      align-items: center;
      border-right: 1px solid white;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      transition: box-shadow 0.2s;
      & .arrow {
        & > div {
          height: 25px;
          width: 3px;
          border-radius: 5px;
          background-color: white;
          position: relative;
          left: 15px;
          &:nth-child(odd) {
            transform: rotate(45deg);
            top: 4px;
          }
          &:nth-child(even) {
            transform: rotate(-45deg);
            top: -4px;
          }
        }
      }
      &.right {
        transform: rotate(180deg);
      }
      &:hover {
        cursor: pointer;
        -webkit-box-shadow: inset -110px 0px 22px -105px rgba(255,255,255,1);
        -moz-box-shadow: inset -110px 0px 22px -105px rgba(255,255,255,1);
        box-shadow: inset -110px 0px 22px -105px rgba(255,255,255,1);
      }
    }
    & #slider {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      & .project {
        margin: 10px;
        margin-top: 37.5px;
        border: 1px solid white;
        height: 200px;
        width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & > * {
          position: relative;
          top: -37.5px;
        }
        & img {
          margin-left: auto;
          margin-right: auto;
          border: 1px solid white;
          height: 75px;
          width: 75px;
          border-radius: 100%;
        }
        & h2 {
          margin: 5px;
        }
        & .info {
          margin-left: 15px;
          margin-right: 15px;
        }
        & hr {
          width: 100%;
        }
        & ul {
          display: flex;
          width: 100%;
          list-style-type: none;
          & li {
            margin: 10px;
            &:hover {
              color: grey;
            }
          }
        }
      }
    }
  }
}
