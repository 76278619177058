nav.page-down {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -5px;
  transition: color 0.4s;
  &:hover {
    color: grey;
    cursor: pointer;
    transition: color 0.4s;
    & .arrow {
      transition: top 0.4s;
      top: 3px;
      & div {
        background-color: grey;
        transition: color 0.4s;
      }
    }
  }
  & .arrow {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0px;
    transition: top 0.4s;
    & div {
      height: 12.5px;
      width: 2px;
      background-color: white;
      margin: 3.5px;
      &:nth-child(odd) {
        transform: rotate(-45deg);
      }
      &:nth-child(even) {
        transform: rotate(45deg);
      }
    }
  }
}
